<template>
  <q-input
    bgColor="white"
    outlined
    dense
    v-bind="$attrs"
    :label="label"
    :value="vmodel"
    :hint="hint"
    @update:modelValue="(event) => $emit('update:vmodel', event)"
  ></q-input>
</template>

<script setup>
const props = defineProps({
  label: {
    type: String,
    default: ''
  },
  hint: {
    type: String,
    default: ''
  },
  vmodel: {
    type: [String, Number],
    default: ''
  }
});
</script>
