<template>
  <q-btn v-bind="$attrs" :icon-right="icon" :label="label" color="white" class="bg-primary" flat style="height: 40px"> </q-btn>
</template>

<script setup>
const props = defineProps({
  label: {
    type: String,
    default: ""
  },
  icon: {
    type: String
  }
});
</script>
